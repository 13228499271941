<template>
	<div class="data-content">
		<div class="top-box">
			<span class="mr10">课程名称：{{name}}</span>
			<div class="top-right-box">
				<button class="add-btn" @click="handleBack">返回</button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="60">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="stuId"
					show-overflow-tooltip
					width="120"
					label="学员编号">
				</el-table-column>
				<el-table-column
					prop="userName"
					width="120"
					show-overflow-tooltip
					label="姓名">
				</el-table-column>
				<el-table-column
					prop="agentName"
					show-overflow-tooltip
					label="公司名称">
				</el-table-column>
				<el-table-column
					label="操作"
					width="130">
					<template slot-scope="scope">
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:offline:delete')">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
	</div>
</template>
<script>
import Pagination from '@/components/Pagination'
export default {
	components: {
		Pagination
	},
	data() {
		return {
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			name: '',
			page: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: [],
			visible: false,
			errorList: []
		}
	},
	created() {
		this.getDataList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		// 获取列表数据
		getDataList() {
			this.name = this.$route.query.name;
			this.page = this.$route.query.page;
			let that = this;
			that.$request.post(
				"courseTrainResultViewMember",
				true,
				{
					trainId: that.$route.query.id
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleBack() {
			this.$router.go(-1);
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前报名人员?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteOfflineResult',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		}
	}
}
</script>
<style lang="scss" scoped>
.block{
	display: block;
}
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 66px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>
<style>
	.el-message .user-message{
		margin: 15px;
	}
</style>
